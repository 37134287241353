import React from "react";

import { KleinboyTag } from "../types";
import { TagBadge } from "./TagBadge";

type Props = {
  tags: KleinboyTag[];
  counter?: { [k: string]: number };
};

export const TagBadgeGroup: React.FC<Props> = ({ tags, counter }: Props) => (
  <span>
    {tags.map((tag) => (
      <span key={tag.key} style={{ margin: "0 2px" }}>
        <TagBadge tag={tag} count={counter != null ? counter[tag.key] : undefined} />
      </span>
    ))}
  </span>
);
