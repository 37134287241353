import React from "react";
import { Link } from "gatsby";

import { KleinboyTag } from "../types";

type Props = {
  tag: KleinboyTag;
  count?: number;
};

export const TagBadge: React.FC<Props> = ({ tag, count }: Props) => (
  <span>
    <Link to={`/tags/${tag.key}/`}>
      {tag.title}
      {count != null ? <span style={{ fontSize: "1.4rem" }}>{` (${count})`}</span> : undefined}
    </Link>
  </span>
);
