import React from "react";
import { PageProps, Link, graphql } from "gatsby";

import { Layout } from "../components/globals/Layout";
import { SEO } from "../components/globals/SEO";
import { KleinboyArticle, KleinboyTag } from "../types";
import { TagBadgeGroup } from "../components/TagBadgeGroup";
import { displayDate } from "../utils/date";
import { fromEntries } from "../utils/map";

export const query = graphql`
  query($articlePath: String!) {
    kleinboyArticle(path: { eq: $articlePath }) {
      path
      title
      description
      publishedTime
      modifiedTime
      tags
      images
      file {
        html
      }
    }
    allKleinboyTag {
      nodes {
        key
        title
      }
    }
  }
`;

type DataProps = {
  kleinboyArticle: KleinboyArticle & {
    file: {
      html: string;
    };
  };
  allKleinboyTag: { nodes: KleinboyTag[] };
};

type PageContext = {
  path: string;
  previous?: KleinboyArticle;
  next?: KleinboyArticle;
};

type LinkLineProps = { link: { path: string; title: string; publishedTime: string } };
const LinkLine: React.FC<LinkLineProps> = ({ link }) => (
  <>
    {displayDate(link.publishedTime, "abs")} <Link to={`/${link.path}/`}>{link.title}</Link>
  </>
);

type PrevNextNav = { previous?: KleinboyArticle; next?: KleinboyArticle };
const PrevNextNav = ({ previous, next }) => (
  <nav className="prev-next">
    <ul style={{ listStyle: "none" }}>
      {next != null ? (
        <li style={{ marginLeft: "0" }}>
          →次回: <LinkLine link={next} />
        </li>
      ) : undefined}
      {previous != null ? (
        <li style={{ marginLeft: "0" }}>
          ←前回: <LinkLine link={previous} />
        </li>
      ) : undefined}
    </ul>
  </nav>
);

const ArticleDetail: React.FC<PageProps<DataProps, PageContext>> = ({ data, pageContext: { previous, next } }) => {
  const {
    title,
    description,
    tags: tagKeys,
    publishedTime,
    file: { html },
  } = data.kleinboyArticle;
  const tagMap = fromEntries(data.allKleinboyTag.nodes.map((n) => [n.key, n]));
  const tags = tagKeys.map((t) => tagMap[t]).filter((x) => x != null);
  return (
    <Layout>
      <SEO title={title} description={description} article={data.kleinboyArticle} tags={tags} />
      <PrevNextNav previous={previous} next={next} />
      <article>
        <header>
          <p style={{ textAlign: "right" }}>
            投稿時刻: {displayDate(publishedTime)}
            {tags.length !== 0 ? (
              <>
                <br />
                タグ: <TagBadgeGroup tags={tags} />
              </>
            ) : undefined}
          </p>
        </header>
        <section id="post-section">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>{" "}
        </section>
        <footer>
          <p style={{ textAlign: "right" }}>
            <strong>{title}</strong>
            <br />
            投稿時刻: {displayDate(publishedTime)}
            {tags.length !== 0 ? (
              <>
                <br />
                タグ: <TagBadgeGroup tags={tags} />
              </>
            ) : undefined}
          </p>
        </footer>
      </article>
      <PrevNextNav previous={previous} next={next} />
    </Layout>
  );
};

export default ArticleDetail;
