const displayRelativeDate = (delta: number) => {
  const s = delta / 1000;
  if (s < 60) {
    return `${s | 0}秒前`;
  }
  const m = s / 60;
  if (m < 60) {
    return `${m | 0}分前`;
  }
  const h = m / 60;
  if (h < 24) {
    return `${h | 0}時間前`;
  }
  const d = h / 24;
  if (d < 31) {
    return `${d | 0}日前`;
  }
  const mon = d / 30;
  if (d < 12) {
    return `${mon | 0}ヶ月以上前`;
  }
  const y = mon / 12;
  return `${y | 0}年以上前`;
};

const displayAbsoluteDate = (s: string) => s.replace("T", " ").replace(/(Z|[+-]\d+:\d+(\.\d+)?)$/, "");

export const displayDate = (s: string, mode = "abs"): string => {
  if (s == null) {
    return "（なし）";
  }
  const abs = displayAbsoluteDate(s);
  if (["rel", "both"].includes(mode)) {
    const delta = Date.now() - Date.parse(s);
    const rel = displayRelativeDate(delta);
    switch (rel) {
      case "rel":
        return rel;
      case "both":
      default:
        return `${abs} （${rel}）`;
    }
  }
  return abs;
};
